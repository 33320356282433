import { atom } from "jotai"
import { atomWithStorage } from 'jotai/utils'
import { viewAtom, addedAtom, showingSummaryAtom, wishListAtom } from "../state"
import { positions, modelFiles, modelFiles2, modelFiles3, getPosition, getGeometries } from "./positions"
import { productsAtom } from "../assets/products"

import { konsole } from "./utils"

window.modelFiles = modelFiles

Object.values(positions).forEach(obj => {
	if (obj?.positions) {
		obj.position = obj.positions
	}

})

function getSkuFromUrl() {
	const url = window.location.pathname;


	return url;
}


export const userKnowledgeAtom = atomWithStorage('userKnowledge', 0)

export const rigAtom = atom('')

export const stepAtom = atom('')
export const stepsAtom = atom({})
export const kitchenAtom = atom('1')

export const fridgeAtom = atom({
	isAdded: false,
	column: true,
	french: false,
	handlePattern: 'rrr',
	wine: false,
	fridgeSize: 36,
	freezerSize: 18,
	columnSetup: [],
	steelcool: false,
	reveal: false,
	doubleReveal: false,
	color: getSkuFromUrl() === '/kitchen3' ? 'graphite' : 'stainless',
	skus: {
		fridge: null,
		columns: [
			"DRR36980RAP/DA", //  right
			"DRZ18980RAP/DA", // middle
			"DRW24980RAP" // left
		],
	},
	accessories: {},
})

export const islandHiddenAtom = atom(false)

export const cookBakeAtom = atom({
	cookAdded: false,
	cookType: 'rangetop',
	island: false, // island or back wall for cooking
	wallOvenCookTop: false, // wall oven and cooktop combination
	skus: {
		cook: getSkuFromUrl() === '/kitchen3' ? 'DTT48T963GM/DA' : 'DTT48T963GS/DA',
		wallOven: getSkuFromUrl() === '/kitchen3' ? 'DOC30M977DM/DA' : 'DOC30M977DS/DA'
	},
	accessories: {},

})

export const ovenAtom = atom({
	ovenAdded: false,
	wallOvenCookTop: false, // wall oven and cooktop combination
	style: 'Contemporary',
	color: 'graphite',
	option: 'double',
	skus: {
		wallOven: getSkuFromUrl() === '/kitchen3' ? 'DOC30M977DM/DA' : 'DOC30M977DS/DA'
	},
	accessories: {},

})


let meshesArray = [];
export const getMeshesArray = () => meshesArray;
export const setMeshesArray = (newArray) => {
	meshesArray = newArray;
};
export const islandAtom = atom(get => get(cookBakeAtom).island)


export const hoodAtom = atom(
	{
		isAdded: false,
		island: false,
		microwave: false,
		hoodType: 'wallhood',
		skus: {
			hood: getSkuFromUrl() === '/kitchen3' ? 'DHD48U990WM/DA' : 'DHD48U990WS/DA'
		},
		accessories: {},
	}
)

export const menuItems = [
	{
		value: 'cooking',
		label: 'Cook',
		rig: 'cookAll',
		children: [
			{
				label: 'Range/Cooktop',
				value: 'cooking',
				viewState: 'cook',

			},
			{
				label: 'Wall Oven',
				value: 'walloven',
				viewState: 'cook-walloven',

			},
			{
				label: 'Ventilation',
				value: 'hood',
				viewState: 'hood',
			},
			{
				label: 'Microwave/Warmer',
				value: 'microwave',
				viewState: 'microwarmer',
			},
		]
	},
	{
		value: 'cool',
		label: 'Cool',
		rig: 'fridge',
		children: [
			{
				label: 'Column Refrigeration',
				value: 'column',
				viewState: 'cool-column',
			},
			{
				label: 'French Door Refrigeration',
				value: 'french',
				viewState: 'cool-french',
			},
			{
				label: 'Side by Side',
				value: 'sidebyside',
				viewState: 'cool-sidebyside',
			},

			{
				label: 'Undercounter',
				value: 'undercounter',
				viewState: 'wine-counter',
				rig: 'counter',
			},
		],
	},
	{
		value: 'wine',
		label: 'Wine',
		rig: 'winefridge',
		children: [

			{
				label: 'Cabinet Wall',
				value: 'cabinetwall',
				viewState: 'wine-rightWall',
			},
		],
	},
	{
		label: 'Dishwasher',
		value: 'dishwasher',
		viewState: 'dishwasher',
	}
]


export const microwarmerAtom = atom({
	mAdded: false,
	wAdded: false,
	drawer: false,
	size: 30,
	warmer: false,
	type: 'drawer',
	warmerColor: 'panelReady',
	skus: {
		microwave: getSkuFromUrl() === '/kitchen3' ? 'DMR30M977WM' : 'DMR30M977WS',
		warmer: getSkuFromUrl() === '/kitchen3' ? 'DWR30U900WM/DA' : 'DWR30U900WS/DA',
	},
	accessories: {},
})


export const dishwasherAtom = atom({
	isAdded: false,
	color: 'stainless',
	skus: {
		dishwasher: 'DDW24G9000APDA'
	}
})


export const wineAtom = atom({
	category: 'wine',
	cAdded: false,
	rAdded: false,
	counterOption: 'DRR24G7400APDA',
	counterColor: getSkuFromUrl() === '/kitchen3' ? 'graphite' : 'stainless',
	rightWallOption: 'DRW24980RAP',
	rightWallColor: getSkuFromUrl() === '/kitchen3' ? 'graphite' : 'stainless',
	skus: {
		counter: 'DRR24G7400APDA',
		rightWall: "DRW24980RAP"
	},
	accessories: {},
})


export const menuParentAtom = atom('')

export const menuAtom = atom(get => {

	let viewState = get(viewAtom)
	let added = get(addedAtom)
	const cool = get(fridgeAtom)
	const cook = get(cookBakeAtom)
	const hood = get(hoodAtom)
	const wine = get(wineAtom)
	window.wine = wine
	const microwarmer = get(microwarmerAtom)
	const showingSummary = get(showingSummaryAtom)


	let keyToCheck = viewState
	if (viewState.includes('-')) keyToCheck = viewState.split('-')[0]
	let acceptable = ['cool', 'cook', 'hood', 'microwarmer', 'dishwasher', 'wine']
	return {
		visible: viewState && acceptable.includes(keyToCheck) || showingSummary,
		type: keyToCheck ?
			added.includes(keyToCheck) ? 'shortcut' : 'full'
			: '',
	}

})
//old function
export const summaryListAtom = atom(async get => {



	// get the skus from the fridge,cook,dishwasher,hood,microwarmer atoms
	let wishList = get(wishListAtom)
	let fridge = get(fridgeAtom)
	let cook = get(cookBakeAtom)
	let dishwasher = get(dishwasherAtom)
	let hood = get(hoodAtom)
	let microwarmer = get(microwarmerAtom)
	let wine = get(wineAtom)
	let oven = get(ovenAtom)
	const products = await get(productsAtom)

	let summaryProducts = []//how to replace this with wishlistatom 

	window.$Store = {
		fridge,
		cook,
		dishwasher,
		hood,
		microwarmer,
		wine,
		oven,
		wishList
	}
	console.log('WISHLIST====================', wishList);


	wishList.forEach(product => {

		//all tests passed
		if (product.category === "fridge" && product.isAdded) {
			let isColumn = product.column
			let accessories = JSON.parse(JSON.stringify(Object.values(product.accessories))).map(x => {
				if (x.sku.startsWith('-')) {
					if (isColumn) {
						x.sku = x.sku.startsWith('-sta') ? 'RAC00MHAASR/DA' : 'RAC00MHAAMS/DA'
					} else if (fridge.size === 36) {
						x.sku = x.sku.startsWith('-sta') ? 'ADRF36MHAASR' : 'ADRF36MHAAMS'
					} else {
						x.sku = x.sku.startsWith('-sta') ? 'ADRF42MHAASR' : 'ADRF42MHAAMS'
					}
				}
				return x
			})
			if (!Array.isArray(accessories)) accessories = []
			let withoutAirFilter = accessories.filter(x => x).filter(x => !x.name.includes('Air '))

			if (isColumn) {
				product.skus.columns.map(x => x.sku ? x : products[x]).filter(x => x).forEach((column, index) => {
					let isLast = index === product.skus.columns.length - 1
					let { image, size, title, category, subCategory, altCategory, sku, style, overAllDimension, features, ...obj } = column
					column.accessories = []

					if (sku.startsWith('DRZ')) {
						column.accessories = withoutAirFilter
					} else if (sku.startsWith('DRR')) {
						column.accessories = accessories
					}

					let hinge = sku.includes('RAP') ? 'R' : 'L'

					if (product.color === 'graphite' || product.color === 'stainless') {
						column.panelSku = `RAC${size}AM${hinge}H${product.color === 'graphite' ? 'MS' : 'SR'}/DA`
					}
					column.del = product.skus.columns.length > 1 ? null : 'fridge'
					summaryProducts.push(column)
				})
				if (product.skus.columns.length > 1) {
					if (product.skus.columns.length === 2) {
						summaryProducts.push({
							title: 'Column Fridge Pairing',
							del: 'fridge-pairing',
							sku: 'fridge',
							accessories: [
								{
									label: 'Pairing Kit 1',
									sku: 'RAC00CPAAAA/DA',
								}
							],
							// name:'Pairing Kit',
							// sku:'RAC00CPAAAA/DA',
							// del:'fridge-pairing'
						})
					} else {
						summaryProducts.push({
							title: 'Column Fridge Pairing',
							del: 'fridge-pairing',
							sku: 'fridge',
							accessories: [
								{
									label: 'Pairing Kit 1',
									qty: 2,
									sku: 'RAC00CPAAAA/DA',
								},
								{
									label: 'Pairing Kit 2',
									sku: 'RAC00TCAAAA/DA',
								}
							]
						})
					}
				}
			} else if (product.skus.fridge) {
				summaryProducts.push({
					...product.skus.fridge,
					accessories
				})
			}

		}
		//all tests passed
		if (product.category === 'walloven' && product.ovenAdded && product.skus.wallOven) {
			product.skus.wallOven = typeof product.skus.wallOven === 'string' ? products[product.skus.wallOven] : product.skus.wallOven

			let { image, title, category, subCategory, altCategory, sku, style, overAllDimension, color, features, size, ...obj } = product.skus.wallOven

			summaryProducts.push({
				del: 'wallOven',
				size,
				image,
				title,
				category,
				subCategory,
				altCategory,
				sku,
				style,
				overAllDimension,
				features,
			})

		}
		//all tests passed
		if (product.category === "cook" && product.cookAdded && product.skus.cook) {
			product.skus.cook = typeof product.skus.cook === 'string' ? products[product.skus.cook] : product.skus.cook
			let { image, title, category, subCategory, altCategory, sku, style, overAllDimension, color, features, size, ...obj } = product.skus.cook
			summaryProducts.push({
				del: 'cook',
				size,
				image,
				title,
				category,
				subCategory,
				altCategory,
				sku,
				style,
				overAllDimension,
				features,
				accessories: Object.values(product.accessories || {})
			})
		}

		//all tests passed
		if (product.category === "dishwasher" && product.isAdded) {
			summaryProducts.push(
				...Object.values(product.skus).map(x => x.sku ? x : products[x]).filter(x => x?.image)
					.map(({ size, image, title, category, subCategory, altCategory, sku, style, overAllDimension, color, features, ...obj }) => {
						color = product.color
						return {
							size, image, title, category, subCategory, altCategory, sku, style, overAllDimension, color, features, accessories: Object.values(product.accessories || {}), obj
						}
					})
			)

		}

		//all tests passed for chimney, all others work however is kinda messed up, appliance shows up after and might not be the right appliance at times
		if (product.category === "hood" && product.isAdded) {
			summaryProducts.push(
				...Object.values(product.skus).filter(x => x?.image)
					.map(({ size, image, title, category, subCategory, altCategory, sku, style, overAllDimension, color, features, ...obj }) => {
						return {
							size, image, title, category, subCategory, altCategory, sku, style: style !== 'All' ? style : '', overAllDimension, color, features,
							accessories: Object.values(product.accessories)
						}
					})
			)
		}

		//all tests passed
		if (product.category === 'microwave' && product.mAdded && product.skus.microwave) {
			product.skus.microwave = typeof product.skus.microwave === 'string' ? products[product.skus.microwave] : product.skus.microwave
			let { image, title, category, subCategory, altCategory, sku, style, overAllDimension, color, features, size, ...obj } = product.skus.microwave
			summaryProducts.push({
				del: 'microwave',
				size,
				image,
				title,
				category,
				subCategory,
				altCategory,
				sku,
				style,
				overAllDimension,
				features,
				accessories: Object.values(product.accessories || {})
			})
		}
		//all tests passed
		if (product.category === 'warmer' && product.wAdded && product.skus.warmer) {
			product.skus.warmer = typeof product.skus.warmer === 'string' ? products[product.skus.warmer] : product.skus.warmer
			let { image, title, category, subCategory, altCategory, sku, style, overAllDimension, color, features, size, ...obj } = product.skus.warmer
			summaryProducts.push({
				del: 'warmer',
				size,
				image,
				title,
				category,
				subCategory,
				altCategory,
				sku,
				style,
				overAllDimension,
				features,
				accessories: Object.values(product.accessories || {})
			})
		}


		//All tests passed
		if (product.category === "wine-counter" && product.cAdded && product.skus.counter) {
			product.skus.counter = typeof product.skus.counter === 'string' ? products[product.skus.counter] : product.skus.counter

			let { image, title, category, subCategory, altCategory, sku, style, overAllDimension, color, features, size, ...obj } = product.skus.counter
			let counterColor = product.counterColor

			summaryProducts.push({
				del: 'wine-counter',
				size,
				image,
				title,
				category,
				subCategory,
				counterColor,
				altCategory,
				sku,
				style,
				overAllDimension,
				features
			})

		}

		if (product.category === "wine-rightwall" && product.rAdded && product.skus.rightWall) {
			product.skus.rightWall = typeof product.skus.rightWall === 'string' ? products[product.skus.rightWall] : product.skus.rightWall
			let { image, title, category, subCategory, altCategory, sku, style, overAllDimension, color, features, size, ...obj } = product.skus.rightWall
			let accessories = Object.values(product.accessories || {})
			if (sku.startsWith('DWS')) {
				accessories = [
					{
						name: 'Argon Gas Canister (2) & Pickup Tube Kit (8)',
						disclaimer: 'Graphite Trim comes included. Customer can decide if they want the dispesner in either finish.',
						sku: 'AWS4000GP'
					}
				]
			}
			summaryProducts.push({
				del: 'wine-rightWall',
				size,
				image,
				title,
				category,
				subCategory,
				altCategory,
				sku,
				style,
				overAllDimension,
				features,
				accessories
			})
		}
	})



	let finalSummary = summaryProducts.filter(x => x && x.title && x.sku)

	return finalSummary

})



export const prdFilesAtom = atom(async get => {

	// get the skus from the fridge,cook,dishwasher,hood,microwarmer atoms
	let fridge = get(fridgeAtom)
	let cook = get(cookBakeAtom)
	let dishwasher = get(dishwasherAtom)
	let hood = get(hoodAtom)
	let microwarmer = get(microwarmerAtom)
	let wine = get(wineAtom)
	let oven = get(ovenAtom)
	let products = await get(productsAtom)
	const kIdx = get(kitchenAtom)
	konsole.log('cook', cook)
	window.$Store = {
		fridge,
		cook,
		dishwasher,
		hood,
		microwarmer,
		wine,
		oven
	}


	const getModelFile = sku => {
		if (kIdx === '1') {
			return modelFiles[sku]
		} else if (kIdx === '2') {
			return modelFiles2[sku] || modelFiles[sku]
		} else if (kIdx === '3') {
			return modelFiles3[sku] || modelFiles[sku]
		}
	}

	window.products = products
	window.products = products
	konsole.log('PRODUCTTTTTSSSSSSSSSSSSSSSSSSSSS =====', products);

	// get the skus from the skus object
	let fridgeSkus = JSON.parse(JSON.stringify(fridge.skus))
	let cookSkus = JSON.parse(JSON.stringify(cook.skus))
	let dishwasherSkus = JSON.parse(JSON.stringify(dishwasher.skus))
	let hoodSkus = JSON.parse(JSON.stringify(hood.skus))
	let microwarmerSkus = JSON.parse(JSON.stringify(microwarmer.skus))
	let wineSkus = JSON.parse(JSON.stringify(wine.skus))
	let ovenSkus = JSON.parse(JSON.stringify(oven.skus))

	konsole.log('fridgeSkus', fridgeSkus)
	konsole.log('cookSkus', cookSkus)
	konsole.log('dishwasherSkus', dishwasherSkus)
	konsole.log('hoodSkus', hoodSkus)
	konsole.log('microwarmerSkus', microwarmerSkus)
	konsole.log('wineSkus', wineSkus)


	let fridgeFiles = []
	let cookFiles = []
	let dishwasherFiles = []
	let hoodFiles = []
	let microwarmerFiles = []
	let wineFiles = []


	// do Fridge Column
	let cLetter = ''
	if (fridge.color.toLowerCase() === 'graphite') cLetter = 'M'
	if (fridge.color.toLowerCase() === 'stainless') cLetter = 'S'
	if (fridge.column && fridge.skus.columns.length) {

		let columnSkus = fridge.skus.columns.map(x => !x.sku ? products[x] : x)

		columnSkus.forEach((column, index) => {
			let sku = column.sku.split('/')[0]
			let file = getModelFile(sku) + cLetter
			if (!cLetter) {
				if (file.startsWith('DRW')) {
					file = file.slice(0, -3) + '-k' + kIdx
					if (kIdx != '3') {
						file += '-lw'
					}
				} else {
					file += '-k' + kIdx
				}
			}

			fridgeFiles.push({ sku, file, leftWall: true, column: index })
		})

	} else if (fridgeSkus.fridge) {
		if (!fridgeSkus.fridge.sku) {
			fridgeSkus.fridge = products[fridgeSkus.fridge]
		}
		if (fridge.french) {
			let sku = fridgeSkus.fridge.sku.split('/')[0]
			let file = getModelFile(sku)
			if (cLetter) {
				if (!sku.includes('C700')) {
					file = getModelFile(sku) + cLetter
				}
			} else if (file.endsWith('AP')) {
				// if (kIdx != '1'){
				file = getModelFile(sku) + '-k' + kIdx
				// }
			}

			if (fridgeSkus.fridge) fridgeFiles.push({ sku, file })
		} else {
			let sku = fridgeSkus.fridge.sku.split('/')[0]
			if (fridgeSkus.fridge) fridgeFiles.push({ sku, file: sku })

		}
	}




	if (cookSkus.cook) {
		if (!cookSkus.cook.sku) {
			cookSkus.cook = products[cookSkus.cook]
		}
		let sku = cookSkus.cook.sku.split('/')[0]
		let cookFile = getModelFile(sku)
		if (cookFile) cookFiles.push({ sku, file: cookFile })
	}
	if (ovenSkus.wallOven) {
		if (!ovenSkus.wallOven.sku) {
			ovenSkus.wallOven = products[ovenSkus.wallOven]
		}
		let sku = ovenSkus.wallOven.sku.split('/')[0]
		let ovenFile = getModelFile(sku)
		if (ovenFile) cookFiles.push({ sku, file: ovenFile, wallOvenCookTop: oven.wallOvenCookTop })
	}

	if (microwarmerSkus.microwave) {
		if (!microwarmerSkus.microwave.sku) {
			microwarmerSkus.microwave = products[microwarmerSkus.microwave]
		}
		let sku = microwarmerSkus.microwave.sku.split('/')[0]
		let file = getModelFile(sku)
		if (file) microwarmerFiles.push({ sku, file })
	}

	if (microwarmerSkus.warmer) {
		if (!microwarmerSkus.warmer.sku) {
			microwarmerSkus.warmer = products[microwarmerSkus.warmer]
		}
		let sku = microwarmerSkus.warmer.sku.split('/')[0]
		let file = getModelFile(sku)
		if (file) microwarmerFiles.push({ sku, file })
	}

	if (dishwasherSkus.dishwasher) {
		if (!dishwasherSkus.dishwasher.sku) {
			dishwasherSkus.dishwasher = products[dishwasherSkus.dishwasher]
		}
		let sku = dishwasherSkus.dishwasher.sku.split('/')[0]
		let cLetter = ''
		if (dishwasher.color === 'graphite') {
			cLetter = 'M'
		} else if (dishwasher.color === 'stainless') {
			cLetter = 'S'
		}

		let file = getModelFile(sku + cLetter)

		if (file) {
			dishwasherFiles.push({
				sku: sku + cLetter,
				file: file
			});
		}
	}
	if (wineSkus.counter) {

		if (!wineSkus.counter.sku) {
			wineSkus.counter = products[wineSkus.counter]
		}
		let cLetter = ''
		if (wine.counterColor === 'graphite') {
			cLetter = 'M'
		} else if (wine.counterColor === 'stainless') {
			cLetter = 'S'
		}

		let sku = wineSkus.counter.sku.split('/')[0]
		let file = getModelFile(sku + cLetter)
		if (file) wineFiles.push({
			sku: sku + cLetter,
			file: file
		});
	}

	if (wineSkus.rightWall) {
		if (!wineSkus.rightWall.sku) {
			wineSkus.rightWall = products[wineSkus.rightWall]
		}
		let sku = wineSkus.rightWall.sku.split('/')[0]

		let file = getModelFile(sku)
		let cLetter = ''
		if (wine.rightWallColor.toLowerCase() === 'graphite') cLetter = 'M'
		if (wine.rightWallColor.toLowerCase() === 'stainless') cLetter = 'S'
		if (sku.startsWith('DRW')) {
			if (cLetter) {
				file += cLetter
			} else {
				file = file.slice(0, -3)
				// if (kIdx != '1'){
				file += '-k' + kIdx
				if (kIdx != '3') {
					file += '-rw'
				}
				// }
			}

		} else {
			file += cLetter
		}
		if (file) wineFiles.push({ sku, file, wine: true, })
	}

	if (hoodSkus.hood) {
		if (!hoodSkus.hood.sku) {
			hoodSkus.hood = products[hoodSkus.hood]
		}
		let sku = hoodSkus.hood.sku.split('/')[0]
		let file = getModelFile(sku)
		if (file) hoodFiles.push({ sku, file })
	}
	let allFiles = [...fridgeFiles, ...cookFiles, ...dishwasherFiles, ...hoodFiles, ...microwarmerFiles, ...wineFiles]
	// Richard: http://localhost:5173?noapps will remove all products
	if (window.location.search.indexOf('noapps') > -1) {
		allFiles = [...wineFiles]
	}

	return allFiles
})

export const leftWallLenAtom = atom(async get => {
	const columns = await get(prdFilesAtom)
	let leftWallLength = 0;
	columns.forEach((column) => {
		if (column.sku.startsWith('DRR24G7') || column.sku.startsWith('DRW24G7')) return;
		if (column.leftWall || column.sku.startsWith('DRF') || column.sku.startsWith('DRZ') || column.sku.startsWith('DRR') || column.sku.startsWith('DRS')) {
			const width = parseInt(column.sku.substring(3, 5))
			leftWallLength += width;
		}
	});
	return leftWallLength;
})

export const modelocAtom = atom(async get => {

	const fridge = get(fridgeAtom)
	let isIsland = get(islandAtom)
	let prdFiles = await get(prdFilesAtom)
	let allLength = prdFiles.length
	let setLength = new Set(prdFiles.map(({ file }) => file)).size
	let isDuplicate = allLength !== setLength
	let transitionalCooktops = [
		"DTG30P875NS",
		"DTG36P875NS",
		"DTI30P876BB",
		"DTI36P876BB",
		"DTI30M977BB",
		"DTI36M977BB"
	]
	const foundTransitionalCooktop = prdFiles.find(x => transitionalCooktops.includes(x.sku.split('/')[0]))

	return prdFiles.map(({ sku, file: fileSKU, flipX = false, wine: rightWall, color, column, wallOvenCookTop }) => {

		let type = ''
		let kitchenType = isIsland ? 'island' : 'kitchen'
		let freezerObj = null
		if (sku.startsWith('DOP') || sku.startsWith('DT') || sku.startsWith('DT')) {
			type = kitchenType
		}
		let columnArr = JSON.parse(JSON.stringify(fridge.skus.columns))
		columnArr = columnArr.map(x => x.sku ? x : products[x])

		let finalCookTopWallOven = Boolean(wallOvenCookTop && sku.startsWith('DOB') && sku.includes('977S') && foundTransitionalCooktop)

		let data = {
			freezer: freezerObj,
			type,
			color,
			file: fileSKU,
			isIsland,
			prdFiles,
			rightWall,
			column,
			columns: columnArr.map(x => x.sku.split('/')[0]),
			wallOvenCookTop: finalCookTopWallOven,
		}
		if (finalCookTopWallOven) {
			konsole.log('finalCookTopWallOvenfinalCookTopWallOvenfinalCookTopWallOvenfinalCookTopWallOvenfinalCookTopWallOvenfinalCookTopWallOvenfinalCookTopWallOvenfinalCookTopWallOvenfinalCookTopWallOvenfinalCookTopWallOvenfinalCookTopWallOvenfinalCookTopWallOvenfinalCookTopWallOven', finalCookTopWallOven)
		}
		if (sku.startsWith('DWS')) {
			konsole.log('POSITION BEFORE', sku, data)
		}

		const { position, rotation, scale } = getPosition(sku, data)
		const { display, hide } = getGeometries(sku, data)
		if (!rightWall) {
			// add .02 to all fridges on the left wall
			if (sku.startsWith('DRF') || sku.startsWith('DRZ') || sku.startsWith('DRR') || sku.startsWith('DRS') || sku.startsWith('DRW')) {
				konsole.log('ADDDINGGGGGGGGGGGGGGGG .0222222222222222222222222222222222222222')
				position[0] += .02
			}
		} else {
			konsole.log('RIGHT WALLL NOT ADDDINGGGGGGGGGGGGGGGG .0222222222222222222222222222222222222222')
		}

		console.log();

		if (sku === 'DRR24G7') {
			scale[0] = -1
		}

		const glbFiles = [
			'DRR24G7400APDA-stainless',
			'DRR24G7400APDA-k2',
			'DRR24G7400APDA-k3',
			'DRR24G7400APDA-graphite',
			'DRR24G7400APDA-k1',
			'DRW24G7500APDA-graphite',
			'DRW24G7500APDA-k2',
			'DRW24G7500APDA-k1',
			'DRW24G7500APDA-k3',
			'DRW24G7500APDA-stainless',
			'DOB30M977DM', // checked
			'DOB30M977DS', // checked
			'DOB30M977SM', // checked
			'DOB30M977SS', // checked
			'DOC30M977DM', // checked
			'DOC30M977DS', // checked
			'DOP36C86DM', // checked
			'DOP48C86DM', // checked
			'DOP48C96DM', // checked
			'DOP48C96DS', // checked
			'DTG30M954FM', //checked
			'DTG30M954FS', //checked
			'DTG30P875NS', //checked
			'DTG36M955FM', // checked
			'DOB30T977DS',// checked
			'DOB30T977SS',// checked
			'DOC30T977DS',// checked
			'DOP30T840GS',// checked
			'DOP36T86DLS',// checked
			'DOP48T960GS',//checked
			'DOP48T963DS',//checked
			'DTG36M955FS',//checked
			'DTG36P875NS',//checked
			'DTI30P876BB',//checked
			'DTI36P876BB',//checked
			'DTT36T960GM',//checked
			'DTT36T960GS',//checked
			'DTT48T960GM',//checked
			'DTT48T960GS',//checked
			'DTT48T963GM',//checked
			'DTT48T963GS',//checked
			'DDW24G9000APDAM',//checked
			'DDW24G9000APDAS',//checked
			'DDW24G9000APM',//checked   
			'DDW24G9000APS',//checked
			'DMR24M977WM',//checked
			'DMR24M977WS',//checked
			'DMR30M977WM',//checked
			'DMR30M977WS',//checked
			'DRW24980LAPM',//checked
			'DRW24980LAPS',//checked
			'DRW24980RAPM',//checked
			'DRW24980RAPS',//checked
			'DWR30U900WM',//checked
			'DWR30U900WS',//checked
			'DWS4000BBM',
			'DWS4000BBS',
			'PCOR30S',//checked
			'DHD30U790LS', //checked
			'DHD30U990CM', //checked
			'DHD30U990CS', //checked
			'DHD30U990WM', //checked
			'DHD30U990WS', // checked
			'DHD36U790LS', //checked
			'DHD36U990CM', //checked
			'DHD36U990CS', //checked
			'DHD36U990IM',//checked
			'DHD36U990IS',//checked
			'DHD36U990WM', //checked
			'DHD36U990WS', //checked
			'DHD42U990IM', //checked
			'DHD42U990IS', //checked
			'DHD48U790LS', //checked
			'DHD48U990CM', //checked
			'DHD48U990CS', //checked
			'DHD48U990WM', //checked
			'DHD48U990WS', //checked
			'DHD54U990IM', //checked
			'DHD54U990IS', //checked


			'DRF365300AP-k3',
			'DRF425300AP-k3',
			'DRF485300AP-k3',
			'DRW24980-k3',
			'DRZ18980LAP-k3',
			'DRZ18980RAP-k3',
			'DRZ24980LAP-k3',
			'DRZ24980RAP-k3',
			'DRZ30980LAP-k3',
			'DRZ30980RAP-k3',
			'DRZ36980LAP-k3',
			'DRZ36980RAP-k3',
			'DWR30U900WP-k3',


			'DRW24980-k2-rw',
			'DWR30U900WP-k2'

		]
		const ext = glbFiles.includes(fileSKU) ? 'glb' : 'gltf'

		const finalObj = {
			sku,
			url: window.location.href.includes('localhost') ? `https://prj-ai.s3.amazonaws.com/staging/${fileSKU}.${ext}` : `https://prj-ai.s3.amazonaws.com/dacor-prds/${fileSKU}.${ext}`,
			position,
			rotation,
			flipX,
			clone: isDuplicate,
			display,
			hide,
			scale,
			rightWall,
			finalCookTopWallOven
		}
		return finalObj
	})

})

export const cooks = atom(async get => {
	let allAppliances = await get(modelocAtom)
	return allAppliances.filter(x => {
		return x.sku.startsWith('DTT') || x.sku.startsWith('DTG') || x.sku.startsWith('DTI') || x.sku.startsWith('DOP')
	})
})
export const hoods = atom(async get => {
	let allAppliances = await get(modelocAtom)
	return allAppliances.filter(x => {
		return x.sku.startsWith('DHD') || x.sku.startsWith('PCO')
	})
})
export const microwaves = atom(async get => {
	let allAppliances = await get(modelocAtom)
	return allAppliances.filter(x => {
		return x.sku.startsWith('DMR') || x.sku.startsWith('DMW') || x.sku.startsWith('DCM')
	})
})
export const dishwashers = atom(async get => {
	let allAppliances = await get(modelocAtom)
	return allAppliances.filter(x => {
		return x.sku.startsWith('DDW')
	})
})
export const wine = atom(async get => {
	let allAppliances = await get(modelocAtom)
	return allAppliances.filter(x => {
		return x.sku.startsWith('DRR24G7') || x.sku.startsWith('DRW24G7') || x.rightWall
	})
})
export const fridges = atom(async get => {
	let allAppliances = await get(modelocAtom)
	return allAppliances.filter(x => {
		return x.sku.startsWith('DRF') || x.sku.startsWith('DRZ') || x.sku.startsWith('DRR') || x.sku.startsWith('DRS') || (x.sku.startsWith('DRW') && !x.rightWall)
	})
})
export const wallOvens = atom(async get => {
	let allAppliances = await get(modelocAtom)
	return allAppliances.filter(x => {
		return x.sku.startsWith('DOB') || x.sku.startsWith('DOC')
	})
})

export const warmer = atom(async get => {
	let allAppliances = await get(modelocAtom)
	return allAppliances.filter(x => {
		return x.sku.startsWith('DWR')
	})
})
